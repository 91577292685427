import React from 'react';
import { useParams } from 'react-router-dom';
import ProductList from '../components/catalogue/productList';

import bunnyhillData from '../data/bunnyhillData.json';
import freakersData from '../data/freakersData.json';
import "./css/catalogue.css"

function Catalogue() {
    const { brand } = useParams();

    // Determine data and image path based on brand
    const data = brand === 'bunnyhill' ? bunnyhillData : freakersData;
    const imagePath = `/${brand}/product-images`;

    return (
        <div className='catalogue-main-con'>
            <div className='catalogue-left-pad'></div>
            <div className='catalogue-middle-pad'>
                <ProductList products={data} brand={brand} />
            </div>
            <div className='catalogue-right-pad'></div>
        </div>


    )
}

export default Catalogue