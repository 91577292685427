import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import "./css/product.css"

const Product = () => {
    const { brand, productId } = useParams();
    const location = useLocation();
    const product = location.state?.product;
    const galleryImages = [
        `/${brand}/product-images/${product.id}/thumbnail.jpg`,
        `/${brand}/product-images/${product.id}/gallery1.jpg`,
        `/${brand}/product-images/${product.id}/gallery2.jpg`,
    ];

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleNext = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex < galleryImages.length - 1 ? prevIndex + 1 : 0
        );
    };

    const handlePrev = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : galleryImages.length - 1
        );
    };

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <div className='product-main-con'>
            <div className='product-left-pad'></div>
            <div className="product-detail">
                <div className="product-gallery">
                    <div className='product-gallery-preview'>
                        {galleryImages.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`${product.title} thumbnail ${index + 1}`}
                                onClick={() => setSelectedImageIndex(index)}
                                className={`preview-image ${index === selectedImageIndex ? 'active' : ''}`}
                            />
                        ))}
                    </div>
                    <div className='product-gallery-selected-image'>
                        <img
                            className="gallery-main-image"
                            src={galleryImages[selectedImageIndex]}
                            alt={product.title}
                        />
                        <button onClick={handlePrev} className="nav-button prev">❮</button>
                        <button onClick={handleNext} className="nav-button next">❯</button>
                    </div>
                </div>
                <div className='product-detail-texts'>
                    <h1 className='product-detail-title'>{product.title}</h1>
                    <p className='product-detail-desc'>
                        {product.description.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </p>
                    <p className='product-detail-info'>
                        {product.information.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </p>
                    <p className='product-detail-sizing'>
                        {product.sizing.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </p>
                    <p className='product-detail-styling'>Styling Tips: {product.styling}</p>
                    <p className='product-detail-price'>{product.price}</p>
                </div>
            </div>
            <div className='product-right-pad'></div>
        </div>

    );
};

export default Product;