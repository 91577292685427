import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./css/header.css";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const logoPNG = "/images/Artboard 3.png";

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <header className="header">
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Birthstone&family=Fraunces:ital,opsz,wght,SOFT,WONK@0,9..144,100..900,0..100,0..1;1,9..144,100..900,0..100,0..1&family=Mako&display=swap');
      </style>
      <div className="header-btm-line">
        <Link to={`/`}>
          <img className="header-logo" src={logoPNG} alt="" />
        </Link>
      </div>
      <div className="header-navigation">
        <div
          className="header-nav-option"
          onMouseEnter={toggleDropdown}
          onMouseLeave={closeDropdown}
        >
          catalogue
          {showDropdown && (
            <div className="dropdown-menu">
              <NavLink
                className="dropdown-item"
                to="/catalogue/bunnyhill"
                onClick={closeDropdown}
              >
                BunnyHill
              </NavLink>
              <NavLink
                className="dropdown-item"
                to="/catalogue/freakers"
                onClick={closeDropdown}
              >
                Freakers
              </NavLink>
            </div>
          )}
        </div>
        <NavLink className="header-nav-option" to="/about">
          about
        </NavLink>
      </div>
    </header>
  );
};

export default Header;
