import React from 'react';
import ProductCard from './productCard';

const ProductList = ({ products, brand }) => (
    <div className="product-list">
        {products.map((product, index) => (
            <ProductCard key={index} product={product} brand={brand} />
        ))}
    </div>
);

export default ProductList;
