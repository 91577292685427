import React from 'react'
import "./css/home.css"

function home() {
  const homePicture = "/images/DSC10068 edited.jpg";

  return (
    // <img className='launch-picture' src={launchPicture} alt="" />
    <div className='home-main-con'>
      <div className='home-left-pad'></div>
      <div className='home-center-con'>
        <img className='home-picture' src={homePicture} alt="" />
        <div className='home-text'>
          <h1 className='home-text-1'>We do things differently here.</h1>
          <p className='home-text-2'>We believe that looking great shouldn't break the bank. We’re dedicated to introducing brands directly from Vietnam without compromising your budget.</p>
          <p className='home-text-3'>Care to join us?</p>
          <p className='home-text-4'>sincerely, 20s</p>
        </div>
      </div>
      <div className='home-right-pad'></div>
    </div>
  )
}

export default home